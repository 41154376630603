module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-anchor-links@1.2.1/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-80},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.9_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pyne Studio","short_name":"Pyne","start_url":"/","background_color":"#ffffff","theme_color":"#F7F7F7","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e930b426512ae135bd957d5efc6c2f8f"},
    },{
      plugin: require('../node_modules/.pnpm/@sentry+gatsby@7.106.1_gatsby@5.12.9_react@18.2.0/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
