exports.components = {
  "component---src-components-product-page-product-page-tsx": () => import("./../../../src/components/productPage/productPage.tsx" /* webpackChunkName: "component---src-components-product-page-product-page-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-success-tsx": () => import("./../../../src/pages/checkout/success.tsx" /* webpackChunkName: "component---src-pages-checkout-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journal-001-jane-birkin-tsx": () => import("./../../../src/pages/journal/001-jane-birkin.tsx" /* webpackChunkName: "component---src-pages-journal-001-jane-birkin-tsx" */),
  "component---src-pages-journal-002-harry-styles-tsx": () => import("./../../../src/pages/journal/002-harry-styles.tsx" /* webpackChunkName: "component---src-pages-journal-002-harry-styles-tsx" */),
  "component---src-pages-journal-003-princess-diana-tsx": () => import("./../../../src/pages/journal/003-princess-diana.tsx" /* webpackChunkName: "component---src-pages-journal-003-princess-diana-tsx" */),
  "component---src-pages-journal-004-diane-keaton-tsx": () => import("./../../../src/pages/journal/004-diane-keaton.tsx" /* webpackChunkName: "component---src-pages-journal-004-diane-keaton-tsx" */),
  "component---src-pages-journal-005-alexa-chung-tsx": () => import("./../../../src/pages/journal/005-alexa-chung.tsx" /* webpackChunkName: "component---src-pages-journal-005-alexa-chung-tsx" */),
  "component---src-pages-journal-006-peggy-lipton-tsx": () => import("./../../../src/pages/journal/006-peggy-lipton.tsx" /* webpackChunkName: "component---src-pages-journal-006-peggy-lipton-tsx" */),
  "component---src-pages-journal-007-james-dean-tsx": () => import("./../../../src/pages/journal/007-james-dean.tsx" /* webpackChunkName: "component---src-pages-journal-007-james-dean-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-men-tsx": () => import("./../../../src/pages/men.tsx" /* webpackChunkName: "component---src-pages-men-tsx" */),
  "component---src-pages-women-tsx": () => import("./../../../src/pages/women.tsx" /* webpackChunkName: "component---src-pages-women-tsx" */)
}

